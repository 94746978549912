import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';

import Header from '../components/header';
import Warning from '../components/warning';


function Authentification(props) {

  const [redirect, setRedirect] = useState(0);
  const [metamask_find, setMetamask_find] = useState(0);


  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setMetamask_find(1);
    }

  }, []);

  async function getAccount() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    //showAccount.innerHTML = account;
    console.log(account);
    if(account !== "") {
      setRedirect(1);
    }
  }




	  if (redirect) {
		  return <Redirect push to="/identity" />;
	}else {

    return (
	    <div>
		    <Header titre="Enterprise Marketplace" balance="" />

        <div className="ph_top center">
          <p>We need you to connect with metamask in order to whitelist your Ethereum address at the end of the KYC/KYB process.</p>
          <p>Metamask will prove that you have the full ownership of this address by signing a message.</p>
        </div>
			<div className="welcome_screen center metamask">

        <a href="#" className={"btn_round anime "+(metamask_find ? 'show' : 'hide')} onClick={() => getAccount()}>Connect with Metamask</a>
        <p className={"bold "+(!metamask_find ? 'show' : 'hide')}>You must install Metamask to continue</p>
        <p className="bold">What is Metamask ?  <a href="https://metamask.io/" target="_blank">Click here</a></p>
			</div>

      <Warning show="0" />
		</div>
    );
    }

}

export default withRouter(Authentification);
