import React, {useState, useEffect, useCallback} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { API_URL } from '../config';

import { ethers } from "ethers";

import Header from '../components/header';
import Warning from '../components/warning';

import arrow from '../assets/arrow.svg';
import logo_j from '../assets/iExec-RLC-logo.svg';
import logo_r from '../assets/iExec-RLC-logo-red.svg';


import {ReactComponent as Img_load} from '../assets/load.svg';

function Swap(props) {


  const [etape, setEtape] = useState(0);
  const [amount, setAmount] = useState(1);
  const [type_swap, setType_swap] = useState(0);
  const [sel_1, setSel_1] = useState("RLC");
  const [sel_2, setSel_2] = useState("eRLC");
  const imgs = [logo_j, logo_r];
  const imgs_2 = [logo_r, logo_j];
  const [contactVisible, setContactVisible] = useState(0);

  const [transaction_hash, setTransaction_hash] = useState("");

  const [alertVisible, setAlertVisible] = useState(0);
  const [message, setMessage] = useState("Insufficient funds in wallet");


  const ERLC = require("@iexec/erlc/build/contracts-min/ERLCSwap.json");
  const RLC = require("rlc-faucet-contract/build/contracts/RLC.json");
  const provider = new ethers.providers.Web3Provider(window.ethereum);


  const rlc = new ethers.Contract("rlc.iexec.eth", RLC.abi, provider.getSigner());
  const erlc = new ethers.Contract("erlc.iexec.eth", ERLC.abi, provider.getSigner());

  let check_transaction;

  useEffect(() => {
    if(alertVisible) {
    const timer = setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
    }
  }, [alertVisible]);


  useEffect(() => {
    if(transaction_hash !== "") {
      check_transaction = setInterval(() => {
        view_transaction();
      }, 2000);
    }else {
      clearInterval(check_transaction);
    }
  }, [transaction_hash]);

  async function view_transaction(e) {
    try {
      const receipt = await provider.getTransactionReceipt(transaction_hash);
      if(receipt) {
        setEtape(2);
        setTransaction_hash("");
        localStorage.setItem('transaction_hash', "");
        clearInterval(check_transaction);
      }
    }catch(e) {
      console.log(e);
    }
  }

  async function check_white_list() {
    try {
      if(!await erlc.isKYC(localStorage.getItem('adr_eth'))) {
        go_home();
      }
    }catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/check');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
      if(log_api.status !== 200) {
        go_home();
      }else {
        check_white_list();
      }
      }
    }

    if(localStorage.getItem('transaction_hash') !== "" && localStorage.getItem('transaction_hash') !== null) {
      setTransaction_hash(localStorage.getItem('transaction_hash'));
      setEtape(1);
    }
  }, []);

  let history = useHistory();

  function go_home() {
    history.push("/");
  }


  async function launch_swap(e) {
    try {
      if(type_swap === 0) {
        setAmount(parseInt(amount));
        const transaction = await rlc.approveAndCall(erlc.address, parseInt(amount)*Math.pow(10,9), '0x');
        setTransaction_hash(transaction.hash);
        localStorage.setItem('transaction_hash', transaction.hash);
        setEtape(1);
      }else if(type_swap === 1) {
        setAmount(parseInt(amount));
        const transaction = await erlc.withdraw(parseInt(amount)*Math.pow(10,9));
        setTransaction_hash(transaction.hash);
        localStorage.setItem('transaction_hash', transaction.hash);
        setEtape(1);
      }
    }catch(e) {

      console.log(e);
      if(e.code === 4001) {
        setMessage("You have cancelled the transaction");
        setAlertVisible(1);
      }else {
        setMessage("Insufficient funds in wallet");
        setAlertVisible(1);
      }
    }
  }

  const reset_swap = (e) => {
    setAmount(1);
    setSel_1("RLC");
    setSel_2("eRLC");
    localStorage.setItem('transaction_hash', "");
    setTransaction_hash("");
    setEtape(0);
  }




  const swap_devise = (e) => {
    if(e === "eRLC") {
      setSel_1("eRLC");
      setSel_2("RLC");
      setType_swap(1);
    }else {
      setSel_1("RLC");
      setSel_2("eRLC");
      setType_swap(0);
    }
  }
  const swap_devise_2 = (e) => {
    if(e === "eRLC") {
      setSel_1("RLC");
      setSel_2("eRLC");
      setType_swap(0);
    }else {
      setSel_1("eRLC");
      setSel_2("RLC");
      setType_swap(1);
    }
  }


  if (etape === 0) {
  return (
    <div>
    <Header titre="Enterprise Marketplace" balance="" adr={"<b>Address Connected : </b>" + localStorage.getItem("adr_eth")} />

    <div className="swap anime" >
    <div className="ph_top center">
    <h1>eRLC Swap Interface</h1>
    </div>
    <div className="cadre_swap">
      <p className="bold">I want to swap :</p>
      <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-between ligne_swap">
        <input type="number" name="name" className="input_swap" value={amount} onChange={e => setAmount(e.target.value)} min="0" step="1" />
        <div>
          <select className="select_rcl" value={sel_1} onChange={e => swap_devise(e.target.value)} >
            <option value={sel_1}>{sel_1}</option>
            <option value={sel_2}>{sel_2}</option>
          </select>
          <i className="fal fa-angle-down fl_down"></i>
        </div>
      </div>
      <img src={imgs[type_swap]} className="logo_swap" alt="iExec" />
      </div>
      <img src={arrow} className="arrow" alt="iExec" />
      <p className="bold">for :</p>
      <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-between ligne_swap">
        <input type="number" name="name" className="input_swap input_swap_2" value={amount} onChange={e => setAmount(e.target.value)} />
        <div>
          <select className="select_rcl" value={sel_2} onChange={e => swap_devise_2(e.target.value)} >
            <option value={sel_2}>{sel_2}</option>
            <option value={sel_1}>{sel_1}</option>
          </select>
          <i className="fal fa-angle-down fl_down"></i>
        </div>
      </div>
      <img src={imgs_2[type_swap]} className="logo_swap" alt="iExec" />
      </div>
      <button className={"btn_round anime"} onClick={(e) => launch_swap(e)}>Swap</button>
    </div>
    </div>
    <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>

    <div onMouseEnter={() => setContactVisible(1)} onMouseLeave={() => setContactVisible(0)} className={"contact anime "+(!contactVisible ? 'cache' : '')}><a href="mailto:kyc@iex.ec?subject=I have a problem with the eRLC Swap Interface" target="_blank"><i className="fas fa-envelope"></i> <p>Have a problem ?</p></a></div>
    <Warning show="0" />
  </div>
  );
  }else if(etape === 1) {
  return (
    <div>
    <Header titre="Enterprise Marketplace" balance="" adr={"<b>Address Connected : </b>" + localStorage.getItem("adr_eth")} />
    <div className="swap load">
    <p className="bold">Please wait for confirmation…</p>
    <Img_load onClick={(e) => view_transaction(e)} />
    </div>

    <Warning show="0" />
  </div>
  );
  }else if(etape === 2) {
  return (
    <div>
    <Header titre="Enterprise Marketplace" balance="" adr={"<b>Address Connected : </b>" + localStorage.getItem("adr_eth")} />
    <div className="swap load">
    <p className="bold">You have succesfully swap</p>
    <p><b>{amount} {sel_1}</b> for <b>{amount} {sel_2}</b></p>
    <button className={"btn_round anime"} onClick={(e) => reset_swap(e)}>Swap again</button>
    </div>

    <Warning show="0" />
  </div>
  );
  }
}

export default withRouter(Swap);
