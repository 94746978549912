import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { API_URL } from '../config';

import { ethers } from "ethers";

import Header from '../components/header';
import Warning from '../components/warning';

import error from '../assets/icons8-cancel.svg';

function Welcome(props) {


  const [redirect, setRedirect] = useState(0);
  const [metamask_find, setMetamask_find] = useState(0);
  const [metamask_req, setMetamask_req] = useState([]);
  const [rdm, setRdm] = useState("");

  const [alertVisible, setAlertVisible] = useState(0);
  const [message, setMessage] = useState("Metamask must be connected to the main Ethereum network");

  useEffect(() => {
    if(alertVisible) {
    const timer = setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
    }
  }, [alertVisible]);


  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
    setMetamask_find(1);
    }

    var log_api = new XMLHttpRequest();
    log_api.open('GET', API_URL+'/metamask');
    log_api.send();
    log_api.onload = function() {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          setRdm(response.rdm);
          setMetamask_req(response.param);
        }else {
          console.log("Error !");
        }
    }

  }, []);

  async function getAccount() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];

    var params = [account, metamask_req];

    try {
      const transactionHash = await window.ethereum.request({
        method: 'eth_signTypedData_v4',
        params: params,
      });
      var params = {
        "signature": transactionHash,
        "random":rdm
      }

      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/login');
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = async function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            var response = JSON.parse(log_api.response);
            localStorage.setItem('adr_eth', response.user[0].eth);
            localStorage.setItem('token_user', response.token);
            try {
              const ERLC = require("@iexec/erlc/build/contracts-min/ERLCSwap.json");

              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const erc20 = new ethers.Contract("erlc.iexec.eth", ERLC.abi, provider);

              if(await erc20.isKYC(response.user[0].eth)) {
                setRedirect(1);
              }else {
                setRedirect(2);
              }
            }catch(e) {
              console.log(e);
            }
          }else {
            console.log("Error !");
          }
        }
      }
    } catch (error) {
      if(error.code === -32603) {
        setAlertVisible(1);
      }else {
        console.error(error);
      }
    }
  }


  if (redirect === 1) {
      return <Redirect push to="/swap" />;
  }else if(redirect === 2) {
    return (
    <div>
      <Header titre="Enterprise Marketplace" adr="" />
    <div className="welcome_screen center error_screen">
      <h1 className="h1_welcome center">We cannot retrieve your ethereum address in our whitelist!</h1>
      <div>
      <img src={error} alt="iExec" />
      </div>
      <div>
      <a href="https://kyc.enterprise.iex.ec/" className={"btn_round anime"} >Go to the iExec KYC/KYB website</a>
      </div>
      <p className="bold"><a href="mailto:kyc@iex.ec" target="_blank">Contact us if you have already completed the KYC process</a></p>
    </div>
    <div className="img_illustration"></div>
    <Warning show="0" />
    </div>
    );
  }else {

  return (
    <div>
      <Header titre="Enterprise Marketplace" balance="" />
      <div className="welcome_screen center">
        <h1 className="h1_welcome center">eRLC Swap Interface</h1>
    <p>Welcome to the eRLC swap interface, please note that this interface is reserved for enterprises or individuals that have succesfully conduct the iExec KYC/KYB process.</p>
    <p>If it’s not your case, please visit <a href="https://kyc.enterprise.iex.ec/">kyc.enterprise.iex.ec</a></p>

    <a href="#" className={"btn_round anime btn_home "+(metamask_find ? 'show' : 'hide')} onClick={() => getAccount()}>Connect with Metamask</a>
    <p className="bold"><a href="https://kyc.enterprise.iex.ec/" target="_blank">I want to do the KYC or KYB.</a></p>
      </div>
    <div className="img_illustration"></div>
    <Warning show="0" />
    <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>
    </div>
  );
  }

}

export default withRouter(Welcome);
