import React, {useState, useEffect} from 'react';
import logo from '../assets/logo.svg';

import default_img from '../assets/girl.jpg';

import { Redirect } from 'react-router';

import {
  Link
} from "react-router-dom";

function Header(props) {

	const [redirect, setRedirect] = useState(0);
	const [skip_log, setSkip_log] = useState(0);

	useEffect(() => {
	}, []);



	if (redirect) {
		return <Redirect push to="/" />;
	}else {

    return (
      <header className="d-flex align-items-center justify-content-between">
        	<div className="d-flex align-items-center justify-content-start">
        		<Link to="/">
        			<img src={logo} className="logo" alt="iExec" referrerPolicy="no-referrer" />
  	  		  </Link>
            <p className="titre_head">{props.titre}</p>
          </div>
          <div>
            <p dangerouslySetInnerHTML={{__html: props.adr}}></p>
          </div>
      </header>
    );
    }
}

export default Header;
