import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



import './css/index.css';

import Welcome from './components/welcome';
import Swap from './components/swap';

import Authentification from './components/authentification';


function App() {


    return (
		<Router>
			<div>
				<Switch>

					<Route path="/" exact component={Welcome}></Route>

          <Route path="/swap" exact component={Swap}></Route>

          <Route path="/authentification" exact component={Authentification}></Route>

				</Switch>
			</div>
		</Router>
    );

}

export default App;
